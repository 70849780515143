import { ApiError } from "@mapi/api";
import {
  FormEvent,
  FormEventHandler,
  MouseEventHandler,
  useState,
  type MouseEvent as ReactDotMouseEvent,
  type ReactNode,
} from "react";
import { toast } from "react-toastify";
import { SafeParseReturnType, ZodSchema, ZodTypeDef } from "zod";
import { isPresent } from "./blank";
import { formStyleDataToJsObject } from "./formHelpers";
import { stringBuffer } from "./string_buffer";
import type { UserErrorPayload } from "./userErrors";

type NativeMouseEvent = MouseEvent;

// React.MouseEvent clashes w/ the namespace for the normal dom MouseEvent.
// This is just convenient and easy.
export type ReactMouseEvent<T extends Element> = ReactDotMouseEvent<
  T,
  NativeMouseEvent
>;

export function onMouseEvent<T extends HTMLElement>(
  e: MouseEventHandler<T>
): MouseEventHandler<T> {
  return e;
}

export function onMouseEventButton(
  e: MouseEventHandler<HTMLButtonElement>
): MouseEventHandler<HTMLButtonElement> {
  return e;
}

export function usePending(): [
  boolean,
  (fn: () => Promise<void>) => Promise<void>,
] {
  const [state, setState] = useState(false);
  const transition = async (fn: () => Promise<void>) => {
    setState(true);
    await fn();
    setState(false);
  };

  return [state, transition];
}

export const flashOnThrow = async <T>(
  fn: () => Promise<T>,
  msg: string | ((e: unknown) => string) | undefined = undefined
) => {
  try {
    return await fn();
  } catch (e: unknown) {
    console.error(e);

    if (msg !== undefined) {
      const displayMessage = typeof msg === "string" ? msg : msg(e);

      toast.error(displayMessage, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return undefined;
    }

    if ((e as ApiError).message !== undefined) {
      toast.error((e as ApiError).message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

    return undefined;
  }
};

export const flashSuccess = (msg: ReactNode) => {
  toast.success(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const flashInfo = (msg: ReactNode) => {
  toast.info(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const flashError = (msg: ReactNode) => {
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const flashUserError = (error: UserErrorPayload) => {
  const msg = stringBuffer(3);
  msg.push(error.message);

  if (error.hint) {
    msg.push(error.hint);
    msg.push(error.hint);
  }

  flashError(msg.string());
};

export type SubmitHandler = FormEventHandler<HTMLFormElement>;

/**
 * Convenience function for creating submit handlers.
 */
export function submitHandler<P extends void | Promise<void>>(
  fn: (e: FormEvent<HTMLFormElement>) => P
): SubmitHandler {
  return fn;
}

export function formToObject<T>(
  form: FormEvent<HTMLFormElement>,
  zodShape: ZodSchema<T, ZodTypeDef, unknown>
): T {
  const data = new FormData(form.currentTarget);
  const obj = formStyleDataToJsObject(data.entries());
  return zodShape.parse(obj);
}

/**
 * Two differensed from `formToObject`
 *  1. blanks are removed.  If a form field has a blank string value, it will be removed from the entries.
 *     this stops a lot of zod refine & transform hacking.
 *  2. It returns zod.safeParse(...) instead of parse(...) so it never throws exceptions
 */
export function safeFormToObject<T>(
  form: FormEvent<HTMLFormElement>,
  zodShape: ZodSchema<T, ZodTypeDef, unknown>
): SafeParseReturnType<unknown, T> {
  const data = new FormData(form.currentTarget);
  const entries = [...data.entries()].filter(([k, v]) => isPresent(v));

  // If all form data is blank, entries will be empty
  const obj = entries.length !== 0 ? formStyleDataToJsObject(entries) : {};

  return zodShape.safeParse(obj);
}

/**
 * Same as safeFormToObject, but async.
 */
export async function safeFormToObjectAsync<T>(
  form: FormEvent<HTMLFormElement>,
  zodShape: ZodSchema<T, ZodTypeDef, unknown>
): Promise<SafeParseReturnType<unknown, T>> {
  const data = new FormData(form.currentTarget);
  const entries = [...data.entries()].filter(([k, v]) => isPresent(v));

  // If all form data is blank, entries will be empty
  const obj = entries.length !== 0 ? formStyleDataToJsObject(entries) : {};

  return zodShape.safeParseAsync(obj);
}

export function flashAndLogError(message: ReactNode, cause: unknown) {
  console.error(message);
  console.error(cause);
  flashError(message);
}

/**
 * Helper function for providing default class names, but letting the prop choose to disable
 * the helpers by including the keyword "reset-class-names" as a class name.
 */
export const classNamesWithReset = (
  defaultClassNames: string,
  classNamesProp: string
) => {
  if (classNamesProp.includes("reset-class-names")) {
    return classNamesProp;
  }

  return `${defaultClassNames.trim()} ${classNamesProp.trim()}`.trim();
};

export const tw = (str: string) => str;
